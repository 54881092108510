document.addEventListener('DOMContentLoaded', () => {
    const themeToggleCheckbox = document.getElementById('theme-toggle-checkbox');
    const body = document.body;
    const logo = document.getElementById('logo');

    // Define logo paths for light and dark themes
    const lightLogo = logo.getAttribute('data-light');
    const darkLogo = logo.getAttribute('data-dark');

    // Function to update theme and logo
    const updateTheme = (theme) => {
        body.classList.remove('theme-light', 'theme-dark');
        body.classList.add(`theme-${theme}`);
        logo.src = theme === 'dark' ? darkLogo : lightLogo;
    };

    // Set initial theme based on stored preference or default to light
    const currentTheme = localStorage.getItem('theme') || 'light';
    updateTheme(currentTheme);
    themeToggleCheckbox.checked = currentTheme === 'dark';

    // Listen for theme changes
    themeToggleCheckbox.addEventListener('change', () => {
        const newTheme = themeToggleCheckbox.checked ? 'dark' : 'light';
        localStorage.setItem('theme', newTheme);
        updateTheme(newTheme);
    });
});
